import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import CollectifNode from '../collectifnode/Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/collectifnode/home" element={<CollectifNode />} />
    </Routes>
  );
}

export default Router;
