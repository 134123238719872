import React, { useState, useRef } from 'react';
import FormInput from '../../../../components/Form/FormInput';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import moment from 'moment';
import { useEffect } from 'react';
import { CONSTANT } from '../../../../utils/constants';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import slugify from 'react-slugify';
import { FileUpload } from 'primereact/fileupload';
import { useToastContext } from '../../../../contexts/ToastContext';
import { ProgressSpinner } from 'primereact/progressspinner';
import RichTextEditor from '../../../../components/Form/RichTextEditor';
import { Dropdown } from 'primereact/dropdown';
import Tag from '../../../../components/Display/Tag';
import { validateURL } from '../../../../utils/stringHelper';

function ResourcePanel({ isOpen, onClose, onCreate, resource, onUpdate, loading }) {
  const [type, setType] = useState('');
  const [status, setStatus] = useState(undefined);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [date, setDate] = useState(undefined);
  const [catalogNumber, setCatalogNumber] = useState('');
  const [labelName, setLabelName] = useState('');
  const [labelUrl, setLabelUrl] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [serie, setSerie] = useState('');
  const [deezerId, setDeezerId] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(undefined);
  const { accountContext } = useAccountContext();
  const [imageLoading, setImageLoading] = useState(false);
  const { showToast } = useToastContext();

  const fileUploadRef = useRef(null);

  useEffect(() => {
    if (resource) {
      setType(resource.type);
      setStatus(resource.status);
      setTitle(resource.title);
      setAuthor(resource.author);
      setCatalogNumber(resource.catalog_number);
      setLabelName(resource.label_name);
      setLabelUrl(resource.label_url);
      setVideoUrl(resource.video_url);
      setSerie(resource.serie);
      setDeezerId(resource.deezer_id);
      setDescription(resource.description);
      setDate(new Date(resource.date));
      setImage(resource.image);
    } else {
      setType(CONSTANT.banquetceleste.resourceTypes[0].value);
      setStatus(undefined);
      setTitle('');
      setAuthor('');
      setCatalogNumber('');
      setLabelName('');
      setLabelUrl('');
      setVideoUrl('');
      setSerie('');
      setDeezerId('');
      setDescription('');
      setDate(undefined);
      setImage(undefined);
    }
  }, [resource, isOpen]);

  const uploadImage = async (imageForm) => {
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/image/`,
      "L'image a bien été ajouté",
      imageForm,
      false,
      true
    ).then((res) => {
      setImage(res);
    });
    setImageLoading(false);
  };

  const onUpload = (event) => {
    if (event.files[0].size > CONSTANT.maxImageSize) {
      showToast('warn', '10Mo max.');
      onClear();
    } else {
      setImageLoading(true);

      const file = event.files[0];
      const imageForm = new FormData();
      imageForm.append('name', slugify(file.name));
      imageForm.append('file', file);
      uploadImage(imageForm);
    }
  };

  const onClear = () => {
    setImage(undefined);
    fileUploadRef.current.clear();
  };

  const handleValidate = () => {
    const resourcePayload = {
      type,
      status,
      title,
      author,
      catalog_number: catalogNumber,
      label_name: labelName,
      label_url: labelUrl,
      video_url: videoUrl,
      serie,
      deezer_id: deezerId,
      description,
      date: moment(date).format('YYYY-MM-DD'),
      image: image?.id
    };
    resource ? onUpdate(resourcePayload) : onCreate(resourcePayload);
  };

  const formIsValid = () => {
    return (
      title.length > 3 &&
      author.length > 3 &&
      description.length > 3 &&
      date &&
      image &&
      type &&
      validateURL(labelUrl) &&
      validateURL(videoUrl)
    );
  };

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider la ${resource ? 'modification' : 'création'}`}
        className="w-full"
        onClick={handleValidate}
        disabled={!formIsValid()}
        severity="success"
        loading={loading}
      />
    </div>
  );

  const dropdownTemplate = (option, props) => {
    if (option) {
      return (
        <Tag
          tag={{
            label: option.label,
            icon: option.icon,
            bgColor: option.bgColor
          }}
          rounded
        />
      );
    }
    return <span>{props.placeholder}</span>;
  };

  return (
    <Sidebar
      style={{ width: 800 }}
      visible={isOpen}
      position="right"
      onHide={() => onClose(false)}
      header={
        <h3 className="text-slate-800 text-2xl font-medium">{`${resource ? 'Modifier' : 'Ajouter'} une ressource`}</h3>
      }>
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={'Type de ressource'} required isValid={type}>
            <Dropdown
              id="type"
              value={type}
              onChange={(e) => setType(e.value)}
              options={CONSTANT.banquetceleste.resourceTypes}
              optionLabel="label"
              placeholder="Veuillez sélectionner le type de ressource"
              valueTemplate={dropdownTemplate}
              itemTemplate={dropdownTemplate}
            />
          </FormInput>

          {resource && (
            <FormInput key="status" label={'État de la ressource'}>
              <Dropdown
                id="status"
                value={status}
                onChange={(e) => setStatus(e.value)}
                options={CONSTANT.banquetceleste.common.statusTypes}
                optionLabel="label"
                placeholder="Veuillez sélectionner l'état de la ressource"
                valueTemplate={dropdownTemplate}
                itemTemplate={dropdownTemplate}
              />
            </FormInput>
          )}
          <FormInput label={'Titre de la ressource'} required isValid={title.length > 3}>
            <InputText
              id="title"
              value={title}
              maxLength={300}
              placeholder="Veuillez saisir le titre de la ressource"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormInput>

          <FormInput label={'Auteur.e.s de la ressource '} required isValid={author.length > 3}>
            <InputText
              id="author"
              value={author}
              maxLength={300}
              placeholder="Veuillez saisir les auteur.e.s de la ressource"
              onChange={(e) => setAuthor(e.target.value)}
            />
          </FormInput>

          <FormInput label={'Date de la ressource'} required isValid={date}>
            <Calendar
              id="date"
              onChange={(e) => setDate(e.value)}
              dateFormat="dd/mm/yy"
              placeholder="Veuillez selectionner la date de la ressource"
              value={date}
              locale="fr"
            />
          </FormInput>

          <FormInput label={'Numéro de catalogue '}>
            <InputText
              id="catalog_number"
              rows={3}
              autoResize
              value={catalogNumber}
              placeholder="Veuillez saisir le numéro de catalogue"
              onChange={(e) => setCatalogNumber(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Nom du label '}>
            <InputText
              id="label_name"
              rows={3}
              autoResize
              value={labelName}
              placeholder="Veuillez saisir le nom du label"
              onChange={(e) => setLabelName(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Site web du label '}>
            <InputText
              id="label_url"
              value={labelUrl}
              placeholder="Veuillez saisir l'url du label"
              onChange={(e) => setLabelUrl(e.target.value)}
            />
          </FormInput>
          <FormInput label={'URL de la vidéo'}>
            <InputText
              id="videoUrl"
              value={videoUrl}
              placeholder="Veuillez saisir l'url de la vidéo"
              onChange={(e) => setVideoUrl(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Série'}>
            <InputText
              id="serie"
              rows={3}
              autoResize
              value={serie}
              placeholder="Veuillez saisir la série"
              onChange={(e) => setSerie(e.target.value)}
            />
          </FormInput>
          <FormInput label={'ID Deezer du ressource'}>
            <InputText
              id="deezer_id"
              rows={3}
              autoResize
              value={deezerId}
              placeholder="Veuillez saisir l'ID Deezer"
              onChange={(e) => setDeezerId(e.target.value)}
            />
          </FormInput>

          <FormInput label={'Description'} required isValid={description.length > 3}>
            <RichTextEditor
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
            />
          </FormInput>

          <FormInput key={'file'} label={`Photo principale (max. 10Mo)`} required isValid={image}>
            {image && (
              <img
                src={image.file}
                className="w-full object-cover"
                alt="Image principale du programme"
              />
            )}
            <div className="flex flex-row gap-x-3">
              {image && (
                <Button
                  key={'delete-img'}
                  label={"Supprimer l'image"}
                  outlined
                  onClick={onClear}
                  severity="danger"
                />
              )}
              {imageLoading ? (
                <div className="w-[50px] h-[50px] p-1">
                  <ProgressSpinner style={{ width: '42px', height: '42px' }} />
                </div>
              ) : (
                <FileUpload
                  ref={fileUploadRef}
                  auto
                  disabled={image}
                  mode="basic"
                  name="file"
                  accept="image/*"
                  customUpload
                  uploadHandler={onUpload}
                  chooseLabel="Depuis mon ordinateur"
                />
              )}
            </div>
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ResourcePanel;
