import React, { useMemo } from 'react';
import PageWrapper from '../../../components/Display/PageWrapper';
import { Divider } from 'primereact/divider';
import VisitsBarChart from './VisitsBarChart';
import DevicePieChart from './DevicePieChart';

function AnalyticsPage({ isActive, config }) {
  const getCharts = useMemo(() => {
    const charts = [];
    config.forEach((c) => {
      switch (c.chartType) {
        case 'visitBarChart':
          charts.push(<VisitsBarChart key={c.page} config={c} />);
          break;

        case 'devicePieChart':
          charts.push(<DevicePieChart key={c.page} config={c} />);
          break;

        default:
          console.log('Chart Type not found');
          break;
      }
    });
    return charts;
  }, []);

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper label="Mesure d'audience">
        {getCharts.map((chart) => (
          <>
            {chart}
            <Divider className="last:hidden" />
          </>
        ))}
      </PageWrapper>
    </div>
  );
}

export default AnalyticsPage;
