import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import FormInput from '../../../../../components/Form/FormInput';

function ReviewPanel({ review, loading, isOpen, onCreate, onUpdate, onClose }) {
  const [author, setAuthor] = useState('');
  const [company, setCompany] = useState('');
  const [content, setContent] = useState('');

  useEffect(() => {
    if (review) {
      setAuthor(review.author);
      setCompany(review.company);
      setContent(review.content);
    } else {
      setAuthor('');
      setCompany('');
      setContent('');
    }
  }, [review, isOpen]);

  const onValidate = () => {
    const reviewPayload = {
      author,
      company,
      content
    };
    review ? onUpdate(reviewPayload) : onCreate(reviewPayload);
  };

  const formIsValid = author && company && content.length > 3;

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider l'avis`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-slate-900 text-2xl font-medium">
        {`${review ? 'Modifier' : 'Ajouter'} un avis client`}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 600 }}
      header={customHeader}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={onClose}
      position="right">
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={'Auteur'} required isValid={author}>
            <InputText
              id="author"
              value={author}
              placeholder="Indiquer l'auteur de l'avis"
              onChange={(e) => setAuthor(e.target.value)}
            />
          </FormInput>
          <FormInput label={"Nom du projet de l'auteur"} required isValid={company}>
            <InputText
              id="company"
              value={company}
              placeholder="Indiquer le nom du projet de l'auteur"
              onChange={(e) => setCompany(e.target.value)}
            />
          </FormInput>
          <FormInput label={'Contenu'} required isValid={content.length > 3}>
            <InputTextarea
              id="content"
              rows={5}
              autoResize
              value={content}
              placeholder="Veuillez saisir le contenu de l'avis"
              onChange={(e) => setContent(e.target.value)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default ReviewPanel;
