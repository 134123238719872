import React, { useMemo } from 'react';
import Section from '../../../../components/Display/Section';
import Data from '../../../../components/Display/Data';
import { CONSTANT, getConstant } from '../../../../utils/constants';
import Tag from '../../../../components/Display/Tag';
import { confirmDialog } from 'primereact/confirmdialog';
import ImageItem from '../../common/image/ImageItem';
import { Button } from 'primereact/button';
import MultipleDataTag from '../../../../components/Display/MultipleDataTag';
import MultiLineData from '../../../../components/Display/MultiLineData';
import ReviewListItem from './Review/ReviewListItem';
import { DataView } from 'primereact/dataview';

function ProjectDetail({
  project,
  handleUpdateProject,
  handleCreateImage,
  handleUpdateImage,
  handleDeleteImage,
  handleDelete,
  handleCreateReview,
  handleDeleteReview,
  handleUpdateReview
}) {
  const updateProjectActions = [
    {
      onClick: handleUpdateProject,
      label: 'Modifier les informations',
      icon: 'pi-pencil'
    }
  ];

  const newImageActions = [
    {
      onClick: handleCreateImage,
      label: 'Ajouter une image',
      icon: 'pi-plus'
    }
  ];

  const addReviewActions = [
    {
      onClick: handleCreateReview,
      label: 'Ajouter un avis',
      icon: 'pi-plus'
    }
  ];

  const confirmReviewDeletion = (review) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette review ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteReview(review)
    });
  };

  const confirmImageDeletion = (image) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cette image ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteImage(image)
    });
  };

  const confirmDeletion = (project) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer ce Projet ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDelete(project)
    });
  };

  const reviewItemTemplate = (review) => {
    return (
      <ReviewListItem
        review={review}
        deleteReview={confirmReviewDeletion}
        updateReview={handleUpdateReview}
      />
    );
  };

  return (
    <div className="h-full w-full flex-col flex gap-5">
      <Section label="Informations Générales" actions={updateProjectActions}>
        <div className="flex flex-wrap gap-5">
          <Data key="title" label={'Titre'} value={project.title} large />
          <Data
            key="type"
            label={'Types'}
            value={project.properties
              .filter((property) => property.type === 'cn_project_type')
              .map((property) => property.label)
              .join(', ')}
            large
          />
          <Data key="year" label={'Année'} value={project.year} />
          <Data key="website_url" label={'Url du site'} value={project.website_url} large />
          <MultipleDataTag
            key="status"
            label={'Statuts'}
            tags={[
              getConstant('collectifnode.inProgress', project.in_progress),
              project.is_highlight && {
                label: 'Projet mis en avant',
                icon: 'star',
                bgColor: 'bg-purple-800'
              }
            ]}
            rounded
          />
        </div>
        <div className="flex flex-wrap gap-5">
          <Data
            key="eco_rating_url"
            label={'Carbon Url'}
            value={project.eco_rating_url}
            disabled={!project.is_highlight}
            large
          />
          <Data
            key="eco_rating"
            label={'Note Carbon'}
            value={`${project.eco_rating ? `${project.eco_rating} %` : ''}`}
            disabled={!project.is_highlight}
          />

          <Data
            key="client_type"
            label={'Type de Client'}
            value={project.client_type}
            disabled={!project.is_highlight}
          />
        </div>
        <div className="flex gap-5">
          <MultipleDataTag
            key="technologies"
            label={'Technologies utilisées'}
            tags={project.properties.filter((property) => property.type === 'cn_technology')}
            disabled={!project.is_highlight}
          />
          <MultipleDataTag
            key="expertises"
            label={'Expertises'}
            tags={project.properties.filter((property) => property.type === 'cn_skill')}
            disabled={!project.is_highlight}
          />
        </div>
        <div className="flex gap-5">
          <MultiLineData key="resume" label={'Résumé'} value={project.resume} large asHtml />
          <MultiLineData
            key="description"
            label={'Description'}
            value={project.description}
            large
            asHtml
          />
        </div>
      </Section>
      <Section label="Avis Client" actions={addReviewActions}>
        <DataView
          value={project.reviews}
          itemTemplate={reviewItemTemplate}
          emptyMessage="Aucune review à afficher"
        />
      </Section>

      <Section label="Image Principale">
        {!project.main_image ? (
          <span className="italic text-slate-400 font-light">Aucune image à afficher</span>
        ) : (
          <img src={project.main_image.file} className="w-80" alt={`Photo de ${project.title}`} />
        )}
      </Section>
      {project.is_highlight && (
        <Section label="Autres images" actions={newImageActions}>
          <div className={`flex flex-wrap gap-3`}>
            {!project.images.length ? (
              <span className="italic text-slate-400 font-light">Aucune image à afficher</span>
            ) : (
              project.images.map((img, index) => (
                <ImageItem
                  key={index}
                  image={img}
                  alt={`Photo de ${project.title} n°${index}`}
                  imageClassName="h-60"
                  updateImage={() => handleUpdateImage(img)}
                  deleteImage={() => confirmImageDeletion(img)}
                />
              ))
            )}
          </div>
        </Section>
      )}

      <Section label="Autres actions">
        <div>
          <Button
            key={'delete'}
            label={'Supprimer le projet'}
            className="!w-54"
            outlined
            onClick={() => confirmDeletion(project)}
            severity="danger"
          />
        </div>
      </Section>
    </div>
  );
}

export default ProjectDetail;
