import React, { useState } from 'react';
import './Data.scss';

function Data({ label, value, large, bgColor, suffix, disabled }) {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    setCopied(true);
    // Copy to clipboard
    await navigator.clipboard.writeText(value);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  const formatValue = () => {
    if (!suffix) return value;
    return `${value} ${suffix}`;
  };

  return (
    <div
      onClick={handleCopy}
      className={`flex flex-col gap-y-1 cursor-pointer data-container ${
        large ? 'w-[340px]' : 'w-40'
      } ${disabled ? 'opacity-30' : ''}`}>
      <div className="w-full flex flex-row justify-between items-center">
        <span className="text-slate-500 text-sm">{label}</span>
        <i
          className={`pi pi-copy copy-icon ${
            copied ? 'opacity-1 text-green-500' : 'opacity-0 text-slate-300'
          }`}
        />
      </div>
      <div className={`relative ${value ? '' : 'text-slate-400 italic font-extralight'}`}>
        <p className="overflow-hidden whitespace-nowrap text-ellipsis">
          {value ? formatValue() : 'Non renseigné'}
        </p>
        <p
          className={`absolute top-0 left-0 ${
            bgColor ? bgColor : 'bg-slate-50'
          } absolute-value-display whitespace-nowrap pr-2 z-20 data-content`}>
          {value ? formatValue() : 'Non renseigné'}
        </p>
      </div>
    </div>
  );
}

export default Data;
