import React from 'react';
import Data from '../../../../../components/Display/Data';
import { Button } from 'primereact/button';
import MultiLineData from '../../../../../components/Display/MultiLineData';

function ReviewListItem({ review, deleteReview, updateReview }) {
  return (
    <div className="flex flex-row w-full justify-between p-5 rounded-lg shadow mb-5">
      <div className={`w-full mb-4 flex flex-col gap-y-3`}>
        <div className="flex-row flex flex-wrap gap-3">
          <Data key="author" label={'Auteur'} value={review.author} large />
          <Data key="company" label={'Projet'} value={review.company} large />
          <MultiLineData
            key="description"
            label={'Description'}
            value={review.content}
            large
            asHtml
          />
        </div>
      </div>
      <div className="flex gap-2">
        <Button
          rounded
          icon="pi pi-pencil"
          severity="success"
          onClick={() => updateReview(review)}
          outlined
          size="small"
          className="!h-9 !w-9"
        />
        <Button
          rounded
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deleteReview(review)}
          outlined
          size="small"
          className="!h-9 !w-9"
        />
      </div>
    </div>
  );
}

export default ReviewListItem;
