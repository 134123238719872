import React, { useEffect, useState } from 'react';
import { useAccountContext } from '../../../contexts/AccountContext';
import { Chart } from 'primereact/chart';
import Section from '../../../components/Display/Section';
import { privateFetch } from '../../../utils/apiHelper';

function VisitsBarChart({ config }) {
  const { accountContext } = useAccountContext();
  const [data, setData] = useState({});

  const fetchData = async () => {
    await privateFetch(
      'GET',
      'analytics',
      `/cu/${accountContext.id}/${config.endPoint}/?scope=${config.scope}`
    ).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const chartOptions = {
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true
      }
    }
  };

  return (
    <Section label={config.label}>
      <h4 className="italic text-gray-600">{config.legend}</h4>
      <Chart type="bar" data={data} options={chartOptions} className="h-[400px]" />
    </Section>
  );
}

export default VisitsBarChart;
