import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../../components/Display/PageWrapper';
import { DataView } from 'primereact/dataview';
import { privateFetch } from '../../../../utils/apiHelper';
import { useAccountContext } from '../../../../contexts/AccountContext';
import { confirmDialog } from 'primereact/confirmdialog';
import { MultiSelect } from 'primereact/multiselect';

import ReviewPanel from './ReviewPanel';
import ReviewItem from './ReviewItem';
import { CONSTANT } from '../../../../utils/constants';

function ReviewPage({ isActive }) {
  const [reviewList, setReviewList] = useState([]);
  const { accountContext } = useAccountContext();
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedReview, setSelectedReview] = useState(undefined);
  const [filter, setFilter] = useState([]);
  const [propertyList, setPropertyList] = useState([]);

  useEffect(() => {
    fetchReviewList();
  }, [filter]);

  const fetchReviewList = async () => {
    await privateFetch(
      'GET',
      'whisper',
      `/cu/${accountContext.id}/review/?properties=${filter}`
    ).then((res) => {
      setReviewList(res);
    });
  };

  const fetchPropertyList = async () => {
    await privateFetch(
      'GET',
      'resources',
      `/cu/${accountContext.id}/property/?types=wp_review`
    ).then((res) => {
      if (res) setPropertyList(res);
    });
  };

  useEffect(() => {
    fetchPropertyList();
  }, []);

  const handleDeleteReview = async (review) => {
    await privateFetch(
      'DELETE',
      'resources',
      `/cu/${accountContext.id}/review/${review.id}/`,
      "L'avis a bien été supprimée"
    ).then(() => {
      fetchReviewList();
      setSelectedReview(undefined);
    });
  };

  const updateReview = async (reviewForm) => {
    setLoading(true);
    await privateFetch(
      'PUT',
      'resources',
      `/cu/${accountContext.id}/review/${selectedReview.id}/`,
      "L'avis a bien été modifiée",
      JSON.stringify(reviewForm)
    ).then(() => {
      fetchReviewList();
      setLoading(false);
      setPanelIsOpen(false);
    });
  };

  const createReview = async (reviewForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'whisper',
      `/cu/${accountContext.id}/review/`,
      "L'avis a bien été ajouté",
      JSON.stringify(reviewForm)
    ).then(() => {
      fetchReviewList();
      setPanelIsOpen(false);
      setLoading(false);
    });
  };

  const handleCreateReview = () => {
    setSelectedReview(undefined);
    setPanelIsOpen(true);
  };

  const newReviewAction = {
    onClick: handleCreateReview,
    label: 'Ajouter un avis',
    icon: 'pi-plus'
  };

  const confirmReviewDeletion = (review) => {
    confirmDialog({
      message: 'Êtes-vous sûr de vouloir supprimer cet avis ?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Oui',
      rejectLabel: 'Annuler',
      accept: () => handleDeleteReview(review)
    });
  };

  const handleUpdateReview = (review) => {
    setSelectedReview(review);
    setPanelIsOpen(true);
  };

  const itemTemplate = (review) => (
    <ReviewItem
      review={review}
      deleteReview={confirmReviewDeletion}
      updateReview={handleUpdateReview}
    />
  );

  return (
    <div className={`h-full w-full flex-col ${isActive ? 'flex' : 'hidden'}`}>
      <PageWrapper label="Avis Client" action={newReviewAction} className="mb-4">
        <div className="fit-content mb-4">
          <MultiSelect
            value={filter}
            options={propertyList}
            optionLabel="label"
            optionValue="slug"
            onChange={(e) => setFilter(e.value)}
            placeholder="Filtrer par métier"
            display="chip"
            className="grow"
            showSelectAll={false}
            filter
          />
        </div>

        <DataView value={reviewList} itemTemplate={itemTemplate} className="pb-20" />
        <ReviewPanel
          review={selectedReview}
          loading={loading}
          isOpen={panelIsOpen}
          onClose={() => {
            setSelectedReview(null);
            setPanelIsOpen(false);
          }}
          onCreate={createReview}
          onUpdate={updateReview}
          propertyList={propertyList}
        />
      </PageWrapper>
    </div>
  );
}

export default ReviewPage;
