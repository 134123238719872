import React from 'react';
import Tag from '../../../../components/Display/Tag';
import { getImage } from '../../../../utils/imageHelper';
import { getConstant } from '../../../../utils/constants';

function ProjectItem({ project, handleClickProject }) {
  return (
    <div
      className="flex flex-row items-start py-5 px-3 gap-x-5 cursor-pointer hover:bg-slate-100"
      onClick={handleClickProject}>
      <img
        className="h-32 w-32 object-cover shadow-md block rounded-md"
        src={project.main_image?.file ? project.main_image.file : getImage('img-ph.png')}
        alt={project.main_image?.label ? project.main_image.label : 'Non renseignée'}
      />
      <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col gap-y-1">
          <div className="text-xl font-bold text-slate-700 ">{project.title}</div>
          <span className="font-medium text-slate-700">{project.year}</span>

          <span className="font-small text-slate-700">
            {project.properties
              .filter((property) => property.type === 'cn_project_type')
              .map((property) => property.label)
              .join(', ')}
          </span>
        </div>
        <div className="flex flex-row gap-x-3">
          <Tag
            tag={{
              label: project.is_highlight && 'Projet mis en avant',
              icon: 'star',
              bgColor: project.is_highlight && 'bg-purple-800'
            }}
            rounded
          />
          <Tag tag={getConstant('collectifnode.inProgress', project.in_progress)} rounded />
        </div>
      </div>
    </div>
  );
}

export default ProjectItem;
