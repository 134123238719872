import React from 'react';
import Welcome from './Welcome';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Navbar from '../common/navbar/Navbar';
import ProjectPage from './Project/ProjectPage';
import AnalyticsPage from '../analytics/AnalyticsPage';
import { CONSTANT } from '../../../utils/constants';

function Home() {
  const { navigationContext } = useNavigationContext();

  return (
    <div className="flex flex-row h-screen w-screen">
      <div className="min-w-64 w-64">
        <Navbar />
      </div>
      <div className="w-full h-auto overflow-scroll">
        <AnalyticsPage
          isActive={navigationContext.acitveAppKey === 'analytics'}
          scope={'collectifnode'}
          config={CONSTANT.collectifnode.analytics}
        />
        <ProjectPage isActive={navigationContext.acitveAppKey === 'cnproject'} />
        <Welcome isActive={!navigationContext.acitveAppKey} />
      </div>
    </div>
  );
}

export default Home;
