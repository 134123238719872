import React from 'react';
import Welcome from './Welcome';
import { useNavigationContext } from '../../../contexts/NavigationContext';
import Navbar from '../common/navbar/Navbar';
import ReviewPage from './Review/ReviewPage';

function Home() {
  const { navigationContext } = useNavigationContext();

  return (
    <div className="flex flex-row h-screen w-screen">
      <div className="min-w-64 w-64">
        <Navbar />
      </div>
      <div className="w-full h-auto overflow-scroll">
        <ReviewPage isActive={navigationContext.acitveAppKey === 'review'} />
        <Welcome isActive={!navigationContext.acitveAppKey} />
      </div>
    </div>
  );
}

export default Home;
