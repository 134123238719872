import React, { useEffect, useState } from 'react';
import { Chart } from 'primereact/chart';
import Section from '../../../components/Display/Section';
import { useAccountContext } from '../../../contexts/AccountContext';
import { privateFetch } from '../../../utils/apiHelper';

function DevicePieChart({ config }) {
  const { accountContext } = useAccountContext();
  const [data, setData] = useState({});

  const fetchData = async () => {
    await privateFetch(
      'GET',
      'analytics',
      `/cu/${accountContext.id}/${config.endPoint}/?scope=${config.scope}`
    ).then((res) => {
      setData(res);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const chartOptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true
        }
      }
    }
  };

  return (
    <Section label={config.label}>
      <h4 className="italic text-gray-600">{config.legend}</h4>
      <Chart type="pie" data={data} options={chartOptions} className="h-[300px]" />
    </Section>
  );
}

export default DevicePieChart;
