import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import Whisper from './Home';

function Router() {
  return (
    <Routes>
      <Route exact path="/whisper/home" element={<Whisper />} />
    </Routes>
  );
}

export default Router;
