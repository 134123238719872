import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

import FormInput from '../Form/FormInput';
import { Button } from 'primereact/button';
import { privateFetch } from '../../utils/apiHelper';
import { useAccountContext } from '../../contexts/AccountContext';
import { MultiSelect } from 'primereact/multiselect';
import { getConstant } from '../../utils/constants';

function PropertyField({ type, selectedPropertyList, setSelectedPropertyList, disabled }) {
  const [label, setLabel] = useState('');
  const labelIsValid = label.length > 2;
  const formIsValid = labelIsValid && type;
  const [loading, setLoading] = useState(false);
  const { accountContext } = useAccountContext();
  const [propertyList, setPropertyList] = useState([]);
  const [propertyManagerVisible, setPropertyManagerVisible] = useState(false);

  useEffect(() => {
    setLabel('');
    fetchPropertyList();
  }, [propertyManagerVisible]);

  const onValidate = () => {
    const payload = {
      label,
      type
    };
    handleCreate(payload);
  };

  const handleCreate = async (propertyForm) => {
    setLoading(true);
    await privateFetch(
      'POST',
      'resources',
      `/cu/${accountContext.id}/property/`,
      'Le Tag a bien été ajouté',
      JSON.stringify(propertyForm)
    ).then(() => {
      setLoading(false);
      setPropertyManagerVisible(false);
    });
  };

  const fetchPropertyList = async () => {
    await privateFetch('GET', 'resources', `/cu/${accountContext.id}/property/?types=${type}`).then(
      (res) => {
        if (res) setPropertyList(res);
      }
    );
  };

  useEffect(() => {
    fetchPropertyList();
  }, []);

  const footerButtons = (
    <div className="manager-footer">
      <Button
        key={'validate'}
        label={'Créer le tag'}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
        className="w-full"
        loading={loading}
      />
    </div>
  );

  return (
    <>
      <div className="flex flex-row justify-between w-full items-center gap-x-3">
        <MultiSelect
          id="properties"
          key="properties"
          className="grow"
          value={selectedPropertyList}
          onChange={(e) => {
            setSelectedPropertyList(e.value);
          }}
          options={propertyList}
          optionLabel="label"
          optionValue="id"
          placeholder="Veuillez sélectionner au moins un item"
          scrollHeight={'350px'}
          showSelectAll={false}
          filter
          display="chip"
          disabled={disabled}
        />
        <Button
          onClick={() => setPropertyManagerVisible(true)}
          icon="pi pi-plus"
          className="hover:bg-slate-100 text-gray-400"
          severity={'secondary'}
          rounded
          outlined
        />
      </div>

      <Dialog
        header="Nouveau tag"
        visible={propertyManagerVisible}
        draggable={false}
        footer={footerButtons}
        className="h-auto p-15 w-[400px]"
        onHide={() => setPropertyManagerVisible(false)}>
        <div className="dialog-content-container">
          <div className="manager-form-wrapper">
            <FormInput
              key="type"
              label={getConstant('propertyTypes', type).label}
              required
              isValid={labelIsValid}>
              <InputText
                value={label}
                placeholder="Veuillez saisir le nom du tag"
                className="m-5"
                onChange={(e) => setLabel(e.target.value)}
              />
            </FormInput>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default PropertyField;
