import React, { useState, useEffect, useRef } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { CONSTANT } from '../../../../utils/constants';
import FormInput from '../../../../components/Form/FormInput';
import { validateURL } from '../../../../utils/stringHelper';

function NewsPanel({ news, loading, isOpen, onCreate, onUpdate, onClose }) {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [sourceLabel, setSourceLabel] = useState(undefined);
  const [sourceUrl, setSourceUrl] = useState(undefined);

  useEffect(() => {
    if (news) {
      setTitle(news.title);
      setContent(news.content);
      setSourceLabel(news.source_label);
      setSourceUrl(news.source_url);
    } else {
      setTitle('');
      setContent('');
      setSourceLabel(undefined);
      setSourceUrl(undefined);
    }
  }, [news, isOpen]);

  const onValidate = () => {
    const newsPayload = {
      title,
      content,
      source_label: sourceLabel,
      source_url: sourceUrl
    };
    news ? onUpdate(newsPayload) : onCreate(newsPayload);
  };

  const formIsValid = content.length > 3 && sourceLabel && validateURL(sourceUrl);

  const footerButtons = (
    <div className="manage-footer">
      <Button
        key={'validate'}
        label={`Valider l'article`}
        loading={loading}
        disabled={!formIsValid}
        onClick={onValidate}
        severity="success"
      />
    </div>
  );

  const customHeader = (
    <React.Fragment>
      <h2 style={{ marginBottom: 0 }} className="text-slate-900 text-2xl font-medium">
        {`${news ? 'Modifier' : 'Ajouter'} un article`}
      </h2>
    </React.Fragment>
  );

  return (
    <Sidebar
      style={{ width: 600 }}
      header={customHeader}
      visible={isOpen}
      maskStyle={{ backgroundColor: '#00000080' }}
      onHide={onClose}
      position="right">
      <div className="sidebar-content-container">
        <div className="form-wrapper">
          <FormInput label={'Source'} required isValid={sourceLabel}>
            <Dropdown
              id="source_label"
              value={sourceLabel}
              onChange={(e) => setSourceLabel(e.value)}
              options={CONSTANT.banquetceleste.availableNewsSources}
              scrollHeight={500}
              filter
              optionLabel="label"
              placeholder="Veuillez sélectionner la source"
            />
          </FormInput>
          <FormInput label={'Contenu'} required isValid={content.length > 3}>
            <InputTextarea
              id="content"
              rows={5}
              autoResize
              value={content}
              placeholder="Veuillez saisir le contenu de l'article"
              onChange={(e) => setContent(e.target.value)}
            />
          </FormInput>
          <FormInput
            label={"Lien externe de l'article (URL)"}
            errorMessage={
              !validateURL(sourceUrl) && "L'URL doit commencer par http:// ou https://"
            }>
            <InputText
              id="source_url"
              value={sourceUrl}
              placeholder="https://www.nouvelobs.com/topnews/20231022"
              onChange={(e) => setSourceUrl(e.target.value)}
              invalid={!validateURL(sourceUrl)}
            />
          </FormInput>
        </div>
        {footerButtons}
      </div>
    </Sidebar>
  );
}

export default NewsPanel;
